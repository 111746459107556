import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Home</h1>
    <p>
      Hi, I'm a software engineer based in Z&uuml;rich, Switzerland. 
      I finished my bachelors studies in the <Link to='https://tuwien.ac.at/'>Technical University of Vienna</Link>. 
      Currently I am working at <Link to='https://www.localsearch.ch/'>localsearch</Link> as a software engineer.
    </p>
  </Layout>
)

export default IndexPage
